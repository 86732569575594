const colors = {
  blue: 'hsla(218, 61%, 46%, 1)',
  blueOpacity: 'hsla(218, 61%, 46%, 0.8)',
  blueLightOpacity: 'hsla(218, 61%, 46%, 0.2)',
  cyan: 'hsla(211, 26%, 57%, 1)',
  darkBlue: 'hsla(213, 100%, 17%, 1)',
  darkBlueOpacity: 'hsla(213, 100%, 17%, 0.8)',
  darkGray: 'hsla(238, 16%, 34%, 1)',
  darkRed: 'hsla(0, 83%, 60%, 1)',
  gray: 'hsla(240, 14%, 90%, 1)',
  green: 'hsla(124, 38%, 59%, 1)',
  greenOpacity: 'hsla(124, 38%, 59%, 0.8)',
  darkGreen: 'hsla(112, 93%, 36%, 1)',
  darkGreenOpacity: 'hsla(112, 93%, 36%, 0.8)',
  lightGray: 'hsla(240, 13%, 94%, 1)',
  lightGreen: 'hsla(131, 67%, 95%, 1)',
  lightRed: 'hsla(0, 100%, 97%, 1)',
  mediumDarkGray: 'hsla(240, 15%, 83%, 1)',
  notificationOrange: 'hsla(32, 88%, 45%, 1)',
  notificationRed: 'hsla(0, 100%, 50%, 1)',
  notificationYellow: 'hsla(47, 97%, 55%, 1)',
  red: 'hsla(0, 83%, 60%, 1)',
  white: 'hsla(0, 0%, 100%, 1)',
  whiteOpacity: 'hsla(0, 0%, 100%, 0.9)',
  transparent: 'hsla(0, 0%, 0%, 0)',
};
export default colors;
