import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import HttpError from './models/httpError';
import ErrorContext from './store/error-context';
import Button from './stories/Button';
import Dialog from './stories/Dialog';
import { ExclamationMarkIcon } from './stories/Icon';
import colors from './colors';

function ErrorContextProvider({ children }: { children?: ReactNode }) {
  const [error, setError] = useState<HttpError | undefined>();

  const { t } = useTranslation(['common', 'errors']);

  const showError = useCallback((httpError: HttpError) => {
    setError(httpError);
  }, []);

  const handleClose = () => setError(undefined);

  const value = useMemo(() => ({ error, showError }), [error, showError]);
  return (
    <ErrorContext.Provider value={value}>
      {error && (
        <Dialog
          open
          onClose={handleClose}
          message={error.message}
          title={error.title}
          icon={<ExclamationMarkIcon width={30} height={30} color={colors.notificationYellow} />}
          dialogActions={
            <Button size="small" width="30%" onClick={handleClose}>
              {t('Close')}
            </Button>
          }
        />
      )}
      {children}
    </ErrorContext.Provider>
  );
}

ErrorContextProvider.defaultProps = {
  children: undefined,
};

export default ErrorContextProvider;
