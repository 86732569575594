import React from 'react';
import {
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps as MuiDialogProps,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Cross2Icon } from './Icon';
import colors from '../colors';

interface DialogProps {
  content?: string | JSX.Element;
  message?: string | JSX.Element;
  onClose: () => void;
  open: boolean;
  maxWidth?: MuiDialogProps['maxWidth'];
  dialogActions?: React.ReactNode;
  icon?: React.ReactNode;
  title?: string;
  showCloseIcon?: boolean;
}

function Dialog({
  content,
  message,
  onClose,
  open,
  maxWidth,
  dialogActions,
  icon,
  title,
  showCloseIcon,
}: DialogProps) {
  const { t } = useTranslation(['common']);

  return (
    <MuiDialog
      open={open}
      fullWidth
      maxWidth={maxWidth}
      onClose={onClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      {showCloseIcon && (
        <IconButton
          aria-label={t('Close dialog')}
          onClick={onClose}
          style={{ position: 'absolute', top: '20px', right: '20px' }}
        >
          <Cross2Icon width={25} color={colors.darkBlue} />
        </IconButton>
      )}
      {title && (
        <DialogTitle id="dialog-title">
          {icon} {title}
        </DialogTitle>
      )}
      <DialogContent sx={{ alignItems: 'flex-start' }}>
        {content}
        {message && <DialogContentText id="dialog-description">{message}</DialogContentText>}
      </DialogContent>
      {dialogActions && <DialogActions>{dialogActions}</DialogActions>}
    </MuiDialog>
  );
}

Dialog.defaultProps = {
  content: undefined,
  dialogActions: undefined,
  message: undefined,
  maxWidth: 'sm',
  title: undefined,
  icon: undefined,
  showCloseIcon: false,
};

export default Dialog;
