import React from 'react';
import HttpError from '../models/httpError';

export interface IErrorContext {
  error?: HttpError;
  showError: (error: HttpError) => void;
}

const ErrorContext = React.createContext<IErrorContext>({} as IErrorContext);

export default ErrorContext;
