import React from 'react';
import { Container, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import Footer from '../stories/Footer';
import AppBar from '../stories/AppBar';
import Button from '../stories/Button';
import '../App.scss';
import './Home.scss';

function Home() {
  return (
    <div className="AppContainer home-background-image">
      <div className="App">
        <AppBar rootPath="/portal" signInText="Kirjaudu" title="KoskiReppu" />

        <main>
          <Container maxWidth="xl">
            <Grid container columnSpacing={8} rowSpacing={2}>
              <Grid item md={6} xs={12}>
                <div className="home-page-box" style={{ marginTop: '50px' }}>
                  <Typography variant="h1" style={{ marginBottom: '32px', textAlign: 'center' }}>
                    KoskiReppu
                  </Typography>

                  <Button
                    component={Link}
                    width="240px"
                    style={{ marginBottom: '14px' }}
                    to="/portal"
                  >
                    Kirjaudu
                  </Button>

                  <p>
                    KoskiReppu-verkkopalvelusta löydät työkalut metsäomaisuutesi hoitamiseen.
                    Palvelun käyttöön tarvitset pankkitunnukset tai mobiilivarmenteen. Vahva
                    tunnistautuminen varmistaa KoskiRepun käytön turvallisuuden.
                  </p>
                  <p>Palvelu on avoin ja maksuton kaikille metsänomistajille.</p>
                </div>

                <div className="home-page-box" style={{ marginTop: '40px', padding: '15px 40px' }}>
                  <p>
                    Jos tarvitset apua KoskiRepun käytössä, ota yhteyttä:
                    <a className="home-page-email" href="mailto:koskireppu@koskisen.com">
                      koskireppu@koskisen.com
                    </a>
                  </p>
                </div>
              </Grid>
            </Grid>
          </Container>
        </main>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
