import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.scss';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'dayjs/locale/fi';
import './i18n';
import Home from './home/Home';
import NotFound from './NotFound';
import ErrorContextProvider from './ErrorContextProvider';

const EmployeeRoutes = lazy(() => import('./EmployeeRoutes'));
const CustomerPortalRoutes = lazy(() => import('./CustomerPortalRoutes'));

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fi">
      <ErrorContextProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/portal/*"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <CustomerPortalRoutes />
              </Suspense>
            }
          />
          <Route
            path="/employee/*"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <EmployeeRoutes />
              </Suspense>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </ErrorContextProvider>
    </LocalizationProvider>
  );
}

export default App;
