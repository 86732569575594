import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import './Footer.scss';

function Footer() {
  const { t } = useTranslation(['common']);

  return (
    <footer>
      <Container maxWidth="xl">
        <div className="footer-element">
          <Typography variant="subtitle2">Yritys</Typography>
          <Typography variant="body1" sx={{ mt: '12px' }}>
            Koskisen Oyj
          </Typography>
        </div>
        <div className="footer-element">
          <Typography variant="subtitle2">&nbsp;</Typography>
          <Typography variant="body1" sx={{ mt: '12px' }}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://koskisen.fi/wp-content/uploads/2023/06/KoskiReppu_tietosuojaseloste_6_6_2023.pdf"
            >
              {t('Privacy policy')}
            </a>
          </Typography>
        </div>
      </Container>
    </footer>
  );
}
export default Footer;
