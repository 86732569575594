import React, { ReactNode } from 'react';
import MuiBadge, { BadgeProps as MuiBadgeProps } from '@mui/material/Badge';

export interface BadgeProps {
  content?: MuiBadgeProps['badgeContent'];
  type?: 'info' | 'notification';
  children?: ReactNode;
  variant?: MuiBadgeProps['variant'];
  invisible?: MuiBadgeProps['invisible'];
}

function Badge({ content, type, variant, invisible, children }: BadgeProps) {
  let color: MuiBadgeProps['color'];
  switch (type) {
    case 'notification':
      color = 'warning';
      break;
    case 'info':
    default:
      color = 'info';
      break;
  }
  return (
    <MuiBadge color={color} badgeContent={content} variant={variant} invisible={invisible}>
      {children}
    </MuiBadge>
  );
}

Badge.defaultProps = {
  type: 'info',
  content: undefined,
  variant: undefined,
  invisible: undefined,
  children: undefined,
};

export default Badge;
