import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MuiAvatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import { NavLink } from 'react-router-dom';
import { MenuTriangleIcon } from './Icon';

export interface AvatarProps {
  initials?: string;
  menuItems?: { path?: string; name: string; onClick?: () => void }[];
}

function Avatar({ initials, menuItems }: AvatarProps) {
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <>
      <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }} aria-label="User menu">
        <MuiAvatar alt={initials} className={Boolean(anchorElUser) === true ? 'menu-open' : ''}>
          {initials}
        </MuiAvatar>
        {Boolean(anchorElUser) && (
          <MenuTriangleIcon
            color="white"
            width={15}
            height={10}
            style={{ position: 'absolute', bottom: '-14px', zIndex: 1300 }}
          />
        )}
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 48,
          horizontal: 50,
        }}
        keepMounted
        onClose={handleCloseUserMenu}
        open={Boolean(anchorElUser)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transitionDuration={0}
        data-testid="avatar-menu"
      >
        {menuItems &&
          menuItems.map(({ name, onClick, path }) => (
            <MenuItem
              key={name}
              onClick={() => {
                handleCloseUserMenu();

                if (onClick) {
                  onClick();
                }
              }}
              sx={{ padding: 0 }}
            >
              {path ? (
                <NavLink to={path} end>
                  {name}
                </NavLink>
              ) : (
                <div className="mobile-menu-item">{name}</div>
              )}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
}

Avatar.defaultProps = {
  initials: '',
  menuItems: [],
};

export default Avatar;
