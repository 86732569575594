import React from 'react';
import Icon, { IconBaseProps } from './IconBase';

function createIcon(
  { className, color, height, width, style, titleAccess }: IconBaseProps,
  type: IconBaseProps['type'],
) {
  return (
    <Icon
      className={className}
      color={color}
      height={height}
      style={style}
      type={type}
      width={width}
      titleAccess={titleAccess}
    />
  );
}

export function ArrowDownIcon(props: IconBaseProps) {
  return createIcon(props, 'arrow-down');
}

export function ArrowUpIcon(props: IconBaseProps) {
  return createIcon(props, 'arrow-up');
}

export function CalendarIcon(props: IconBaseProps) {
  return createIcon(props, 'calendar');
}

export function CalendarSmallIcon(props: IconBaseProps) {
  return createIcon(props, 'calendar-small');
}

export function CrossIcon(props: IconBaseProps) {
  return createIcon(props, 'cross');
}

export function Cross2Icon(props: IconBaseProps) {
  return createIcon(props, 'cross2');
}

export function DownloadIcon(props: IconBaseProps) {
  return createIcon(props, 'download');
}

export function EditIcon(props: IconBaseProps) {
  return createIcon(props, 'edit');
}

export function ExclamationMarkIcon(props: IconBaseProps) {
  return createIcon(props, 'exclamation-mark');
}

export function HamburgerIcon(props: IconBaseProps) {
  return createIcon(props, 'hamburger');
}

export function InfoIcon(props: IconBaseProps) {
  return createIcon(props, 'info');
}

export function KoskisenIcon(props: IconBaseProps) {
  return createIcon(props, 'koskisen');
}

export function MenuTriangleIcon(props: IconBaseProps) {
  return createIcon(props, 'menu-triangle');
}

export function PlusIcon(props: IconBaseProps) {
  return createIcon(props, 'plus');
}

export function PrintIcon(props: IconBaseProps) {
  return createIcon(props, 'print');
}

export function SearchIcon(props: IconBaseProps) {
  return createIcon(props, 'search');
}

export function CheckMarkIcon(props: IconBaseProps) {
  return createIcon(props, 'check-mark');
}

export function PdfIcon(props: IconBaseProps) {
  return createIcon(props, 'pdf');
}

export function GeolocationEnableIcon(props: IconBaseProps) {
  return createIcon(props, 'geolocation-enable');
}

export function GeolocationDisableIcon(props: IconBaseProps) {
  return createIcon(props, 'geolocation-disable');
}

export default Icon;
