import { createTheme } from '@mui/material/styles';
import { createBreakpoints } from '@mui/system';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/700.css';
import '@fontsource/montserrat/800.css';
import colors from './colors';

const LARGE_ELEMENT_HEIGHT = '59px';

const breakpoints = createBreakpoints({});

const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat',
    fontSize: 16,
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        filledSuccess: {
          backgroundColor: colors.darkBlue,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: colors.darkBlue,
          color: colors.white,
          border: '1px solid',
          borderColor: colors.white,
          fontSize: '14px',
          fontWeight: 700,
          width: '36px',
          height: '36px',
          paddingTop: '2px',
          '&.menu-open': {
            backgroundColor: colors.white,
            color: colors.darkBlue,
          },
          ':hover': {
            backgroundColor: colors.white,
            color: colors.darkBlue,
          },
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        standard: {
          fontWeight: 700,
          right: '-10px',
          color: 'hsla(0, 0%, 0%, 1)',
        },
        colorInfo: {
          backgroundColor: colors.darkGreen,
        },
        colorWarning: {
          backgroundColor: colors.notificationYellow,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        sizeSmall: {
          fontSize: '14px',
          minHeight: '46px',
          minWidth: '142px',
        },
        containedPrimary: {
          ':focus': {
            backgroundColor: 'hsla(213, 100%, 32%, 1)',
          },
          ':hover': {
            backgroundColor: 'hsla(213, 100%, 32%, 1)',
          },
        },
        containedSecondary: {
          color: colors.darkGray,
          fontWeight: 400,
        },
        containedInfo: {
          color: colors.darkGray,
        },
        root: {
          fontWeight: 700,
          color: colors.white,
          borderRadius: '10px',
          minHeight: LARGE_ELEMENT_HEIGHT,
          padding: '0 28px',
          textTransform: 'none',
        },
        startIcon: {
          marginLeft: '-11px',
        },
        endIcon: {
          marginRight: '-8px',
        },
        outlined: {
          border: '1px solid',
          borderColor: colors.darkBlue,
          backgroundColor: colors.white,
          color: colors.darkGray,
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true, // No more ripple, on the whole application
      },
      styleOverrides: {
        root: {
          '&.MuiIconButton-root': {
            marginRight: '-8px', // Date picker icon margin
            ':focus': {
              backgroundColor: 'hsla(0, 0%, 0%, 0.04)',
            },
          },
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          [breakpoints.up('sm')]: {
            paddingLeft: '73px',
            paddingRight: '73px',
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          boxShadow: '0px 5px 30px 5px 	hsla(0, 0%, 0%, 0.1)',
          borderRadius: '10px',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          justifyContent: 'center',
          paddingBottom: '33px',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          [breakpoints.up('sm')]: {
            padding: '40px 50px 60px 50px',
          },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          fontWeight: 500,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          color: colors.darkGray,
          fontWeight: 600,
          fontSize: '1.5rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '24px 24px',
          svg: {
            paddingRight: '5px',
          },
          [breakpoints.up('sm')]: {
            padding: '24px 24px',
            fontWeight: 700,
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: colors.mediumDarkGray,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: 12,
          marginLeft: 0,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        marginNormal: {
          marginTop: '24px',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          margin: 0,
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          color: 'unset', // Remove fixed color so that color is inherited from the font color
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '12px',
          fontWeight: 700,
          transform: 'translate(0, -24px)', // Move label on top of the input element
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          boxShadow: '0px 5px 30px 5px hsla(0, 0%, 0%, 0.1)',
          padding: 0,
          minWidth: '200px',
        },
        paper: {
          '&.MuiPaper-root': {
            borderRadius: '10px',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          a: {
            textDecoration: 'none',
            color: colors.darkGray,
            width: '100%',
            padding: '9px 17px',
          },
          minHeight: '20px',
          padding: '9px 17px',
          textDecoration: 'none !important',
          fontSize: '16px',
          fontWeight: 500,
          '.active': {
            fontWeight: 700,
            background: colors.white,
            boxShadow: '0px 5px 30px hsla(0, 0%, 0%, 0.1)',
          },
          '.mobile-menu-item': {
            fontSize: '16px',
            fontWeight: 500,
            color: colors.darkGray,
            padding: '9px 17px',
            width: '100%',
          },
          '.mobile-menu-item-disabled': {
            cursor: 'not-allowed',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: 0,
          ':disabled': {
            color: colors.darkBlue,
            WebkitTextFillColor: 'unset',
          },
          '::placeholder': {
            color: colors.darkBlue,
            opacity: 1,
          },
        },
        notchedOutline: {
          border: 'none',
        },
        root: {
          backgroundColor: colors.lightGray,
          borderRadius: '10px',
          fontSize: '16px',
          minHeight: LARGE_ELEMENT_HEIGHT,
          textAlign: 'left',
          '&.Mui-error': {
            border: '2px solid',
            borderColor: colors.red,
          },
          padding: '12px 22px',
        },
        colorSecondary: {
          backgroundColor: colors.white,
        },
        sizeSmall: {
          fontSize: '14px',
          minHeight: '46px',
          padding: '12px 16px',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          padding: '18px 22px',
          '&.MuiOutlinedInput-input': {
            paddingRight: '55px',
          },
        },
        icon: {
          position: 'relative', // https://github.com/mui/material-ui/issues/17799#issuecomment-589678881
          marginRight: '10px',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.MuiTableRow-hover:hover': {
            cursor: 'pointer',
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            '&.MuiToggleButtonGroup-grouped': {
              borderLeft: '1px solid transparent',
            },
            backgroundColor: colors.white,
            fontWeight: 600,
            boxShadow: '0px 7px 5px -2px hsla(0, 0%, 0%, 0.1)',
            span: {
              marginBottom: '2px',
            },
            ':focus': {
              backgroundColor: colors.white,
            },
            ':hover': {
              backgroundColor: colors.white,
            },
          },
          '&.Mui-disabled': {
            border: '0px',
          },
          span: {
            marginTop: '2px',
          },
          color: colors.darkGray,
          backgroundColor: colors.gray,
          textTransform: 'none',
          fontSize: '14px',
          lineHeight: '1em',
          fontWeight: 400,
          borderRadius: '100px',
          border: '0px',
          height: '46px',
          minWidth: '112px',
          ':focus': {
            backgroundColor: 'hsla(240, 10%, 84%, 1)',
          },
          ':hover': {
            backgroundColor: 'hsla(240, 10%, 84%, 1)',
          },
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          margin: '8px 0',
        },
        grouped: {
          ':not(:last-of-type)': {
            span: {
              marginRight: 0,
            },
          },
          ':not(:first-of-type)': {
            borderLeft: '1px solid hsla(0, 0%, 0%, 0.06)',
            span: {
              marginLeft: 0,
            },
          },
          span: {
            marginLeft: '10px',
            marginRight: '10px',
          },
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          height: '100px', // Menu height
          paddingRight: '25px',
          [breakpoints.up('sm')]: {
            paddingRight: '70px',
          },
          '.menu-item': {
            boxSizing: 'border-box',
            color: 'white',
            fontWeight: 600,
            fontFamily: 'Montserrat',
            textDecoration: 'none',
            margin: '0 26px',
            height: '100px',
            display: 'flex',
            alignItems: 'center',
            borderBottom: '5px solid',
            borderColor: 'transparent',
            paddingTop: '5px',
            ':hover, :focus': {
              color: 'hsla(0, 0%, 100%, 0.8)',
            },
            ' > span': {
              width: '100%',
            },
          },
          '.active-menu-item': {
            fontWeight: 700,
            borderColor: colors.white,
            ':hover, :focus': {
              color: colors.white,
            },
          },
          '.disabled-menu-item': {
            cursor: 'not-allowed',
            color: 'hsla(0, 0%, 100%, 0.8)',
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          textAlign: 'left',
        },
        h1: {
          fontWeight: 600,
          fontSize: '36px',
          lineHeight: '1em',
          [breakpoints.up('sm')]: {
            fontSize: '40px',
          },
          [breakpoints.up('md')]: {
            fontSize: '50px',
          },
        },
        h2: {
          fontWeight: 600,
          fontSize: '30px',
          lineHeight: '47px',
        },
        h3: {
          fontWeight: 600,
          fontSize: '18px',
          lineHeight: '22px',
        },
        h4: {
          fontWeight: 600,
          fontSize: '16px',
          lineHeight: '20px',
        },
        subtitle1: {
          fontWeight: 700,
          fontSize: '14px',
          lineHeight: '17px',
        },
        subtitle2: {
          fontWeight: 700,
          fontSize: '12px',
          lineHeight: '15px',
        },
        body1: {
          fontWeight: 400,
          fontSize: '16px',
          lineHeight: '19.5px',
        },
      },
    },
  },
  palette: {
    background: {
      default: colors.white,
      paper: colors.white,
    },
    primary: {
      main: colors.darkBlue,
    },
    secondary: {
      main: colors.lightGray,
    },
    info: {
      main: colors.white,
    },
    error: {
      main: colors.red,
    },
    text: {
      primary: colors.darkBlue,
      secondary: colors.darkBlue,
    },
    success: {
      main: colors.blue,
    },
  },
  // Set default spacing to 5px. (ie. theme.spacing(2) adds 2 x 5px spacing).
  spacing: 5,
});

export default theme;
