import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

import { ReactComponent as ArrowDown } from './svg/arrow-down.svg';
import { ReactComponent as ArrowUp } from './svg/arrow-up.svg';
import { ReactComponent as Calendar } from './svg/calendar.svg';
import { ReactComponent as CalendarSmall } from './svg/calendar_small.svg';
import { ReactComponent as Cross } from './svg/cross.svg';
import { ReactComponent as Cross2 } from './svg/cross2.svg';
import { ReactComponent as Download } from './svg/download.svg';
import { ReactComponent as Edit } from './svg/edit.svg';
import { ReactComponent as ExclamationMark } from './svg/exclamation-mark.svg';
import { ReactComponent as Hamburger } from './svg/hamburger.svg';
import { ReactComponent as Info } from './svg/info.svg';
import { ReactComponent as Koskisen } from './svg/koskisen.svg';
import { ReactComponent as MenuTriangle } from './svg/menu-triangle.svg';
import { ReactComponent as Plus } from './svg/plus.svg';
import { ReactComponent as Print } from './svg/print.svg';
import { ReactComponent as Search } from './svg/search.svg';
import { ReactComponent as CheckMark } from './svg/check-mark.svg';
import { ReactComponent as Pdf } from './svg/pdf.svg';
import { ReactComponent as GeolocationIcon } from './svg/geolocation.svg';
import { ReactComponent as GeolocationDisableIcon } from './svg/geolocation-disable.svg';

export interface IconBaseProps {
  className?: string;
  color?: string;
  height?: number;
  style?: React.CSSProperties;
  type?:
    | 'arrow-down'
    | 'arrow-up'
    | 'calendar'
    | 'calendar-small'
    | 'cross'
    | 'cross2'
    | 'download'
    | 'edit'
    | 'exclamation-mark'
    | 'hamburger'
    | 'info'
    | 'koskisen'
    | 'menu-triangle'
    | 'plus'
    | 'print'
    | 'search'
    | 'check-mark'
    | 'pdf'
    | 'geolocation-enable'
    | 'geolocation-disable';
  titleAccess?: SvgIconProps['titleAccess'];
  width?: number;
}

function IconBase({ className, color, height, style, type, width, titleAccess }: IconBaseProps) {
  let svgComponent: React.FunctionComponent;

  let iconHeight = height;
  let iconWidth = width;

  if (!height && !width) {
    iconHeight = 16;
    iconWidth = 16;
  } else if (!height && width) {
    iconHeight = width;
  } else if (!width && height) {
    iconWidth = height;
  }

  switch (type) {
    case 'arrow-down':
      svgComponent = ArrowDown;
      break;

    case 'arrow-up':
      svgComponent = ArrowUp;
      break;

    case 'calendar':
      svgComponent = Calendar;
      break;

    case 'calendar-small':
      svgComponent = CalendarSmall;
      break;

    case 'cross':
      svgComponent = Cross;
      break;

    case 'cross2':
      svgComponent = Cross2;
      break;

    case 'download':
      svgComponent = Download;
      break;

    case 'edit':
      svgComponent = Edit;
      break;

    case 'exclamation-mark':
      svgComponent = ExclamationMark;
      break;

    case 'hamburger':
      svgComponent = Hamburger;
      break;

    case 'info':
      svgComponent = Info;
      break;

    case 'koskisen':
      svgComponent = Koskisen;
      break;

    case 'menu-triangle':
      svgComponent = MenuTriangle;
      break;

    case 'print':
      svgComponent = Print;
      break;

    case 'search':
      svgComponent = Search;
      break;

    case 'check-mark':
      svgComponent = CheckMark;
      break;

    case 'pdf':
      svgComponent = Pdf;
      break;

    case 'geolocation-enable':
      svgComponent = GeolocationIcon;
      break;

    case 'geolocation-disable':
      svgComponent = GeolocationDisableIcon;
      break;

    case 'plus':
    default:
      svgComponent = Plus;
      break;
  }

  const componentStyle: {
    height?: number | string;
    width?: number | string;
    color?: string;
  } = {
    height: iconHeight,
    width: iconWidth,
  };

  if (color) {
    componentStyle.color = color;
  }

  const combinedStyles = { ...componentStyle, ...style };

  return (
    <SvgIcon
      className={className}
      component={svgComponent}
      inheritViewBox
      style={combinedStyles}
      titleAccess={titleAccess}
    />
  );
}

IconBase.defaultProps = {
  className: '',
  color: undefined,
  height: undefined,
  style: undefined,
  type: 'plus',
  titleAccess: undefined,
  width: undefined,
};

export default IconBase;
