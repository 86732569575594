import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import commonFI from './locales/fi/common.json';
import contractsFI from './locales/fi/contracts.json';
import customersFI from './locales/fi/customers.json';
import employeesFI from './locales/fi/employees.json';
import errorsFI from './locales/fi/errors.json';
import interestRatesFI from './locales/fi/interestRates.json';
import messagesFI from './locales/fi/messages.json';
import myForestFI from './locales/fi/myForest.json';
import profilesFI from './locales/fi/profiles.json';
import transactionsFI from './locales/fi/transactions.json';
import offersFI from './locales/fi/offers.json';
import documentsFI from './locales/fi/documents.json';
import reportsFI from './locales/fi/reports.json';

const resources = {
  fi: {
    common: commonFI,
    contracts: contractsFI,
    customers: customersFI,
    employees: employeesFI,
    errors: errorsFI,
    interestRates: interestRatesFI,
    messages: messagesFI,
    myForest: myForestFI,
    profiles: profilesFI,
    transactions: transactionsFI,
    offers: offersFI,
    documents: documentsFI,
    reports: reportsFI,
  },
};

i18n
  // Pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // Init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    lng: 'fi',
    fallbackLng: 'fi',
    interpolation: {
      escapeValue: false, // Not needed for react as it escapes by default
    },
    resources,
  });

export default i18n;
