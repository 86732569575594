import React from 'react';
import { Link } from 'react-router-dom';
import './App.scss';

function NotFound() {
  return (
    <div className="App">
      <header className="App-header">
        <span>404 Not Found</span>
        <Link to="/" className="App-link">
          Go back to homepage
        </Link>
      </header>
    </div>
  );
}

export default NotFound;
