import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { NavLink } from 'react-router-dom';
import { HamburgerIcon, MenuTriangleIcon } from './Icon';
import Badge, { BadgeProps } from './Badge';

export interface MobileMenuProps {
  menuItems?: {
    path?: string;
    name: string;
    badgeContent?: {
      content: BadgeProps['content'];
      type: BadgeProps['type'];
    };
  }[];
}

function MobileMenu({ menuItems }: MobileMenuProps) {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const notification = menuItems?.find((m) => m.badgeContent?.content);

  return (
    <>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleOpenNavMenu}
        color="inherit"
        data-testid="mobile-menu-icon"
      >
        <Badge invisible={!notification} type={notification?.badgeContent?.type} variant="dot">
          <HamburgerIcon height={26} width={30} />
        </Badge>
        {Boolean(anchorElNav) && (
          <MenuTriangleIcon
            width={15}
            height={10}
            style={{ position: 'absolute', bottom: '-7px', zIndex: 1300 }}
          />
        )}
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: 55,
          horizontal: 60,
        }}
        keepMounted
        onClose={handleCloseNavMenu}
        open={Boolean(anchorElNav)}
        sx={{
          display: { xs: 'block' },
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transitionDuration={0}
        data-testid="mobile-menu"
      >
        {menuItems &&
          menuItems.map(({ name, path, badgeContent }) => (
            <MenuItem key={name} onClick={handleCloseNavMenu} sx={{ padding: 0 }}>
              {path ? (
                <NavLink style={{ display: 'flex', gap: '5px', alignItems: 'center' }} to={path}>
                  {name}
                  <Badge content={badgeContent?.content} type={badgeContent?.type} />
                </NavLink>
              ) : (
                <div className="mobile-menu-item disabled-mobile-menu-item">{name}</div>
              )}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
}

MobileMenu.defaultProps = {
  menuItems: [],
};

export default MobileMenu;
