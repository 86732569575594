import * as React from 'react';
import { AppBar as MuiAppBar, Box, Container, Toolbar, Typography } from '@mui/material';
import { Link, NavLink } from 'react-router-dom';
import MobileMenu from '../MobileMenu';
import Avatar from '../Avatar';
import Badge, { BadgeProps } from '../Badge';
import { KoskisenIcon } from '../Icon';
import colors from '../../colors';
import './AppBar.scss';

export interface MenuItem {
  name: string;
  onClick?: () => void;
  path?: string;
  badgeContent?: {
    content: BadgeProps['content'];
    type: BadgeProps['type'];
  };
}

export interface EnvironmentBannerProps {
  text: string;
}

function EnvironmentBanner({ text }: EnvironmentBannerProps) {
  return <div className="environment-banner">{text.charAt(0).toUpperCase() + text.slice(1)}</div>;
}

export interface AppBarProps {
  environmentBannerText?: string;
  initials?: string | undefined;
  menuItems?: MenuItem[];
  rootPath?: string;
  signInText?: string;
  userMenuItems?: MenuItem[];
  title?: string;
}

function AppBar({
  environmentBannerText,
  initials,
  menuItems,
  userMenuItems,
  rootPath,
  signInText,
  title,
}: AppBarProps) {
  return (
    <MuiAppBar position="static" elevation={0}>
      <Container maxWidth="xl" sx={{ padding: 0 }} style={{ padding: 0 }}>
        <Toolbar disableGutters>
          {environmentBannerText && <EnvironmentBanner text={environmentBannerText} />}
          <Link className="appbar-logo" aria-label="Etusivu" to={rootPath ?? '/'}>
            <KoskisenIcon color={colors.darkBlue} height={82} width={91} />
          </Link>

          <Box
            sx={{
              flexGrow: 0,
              paddingLeft: '40px',
              display: { xs: 'none', lg: 'flex' },
            }}
          >
            {menuItems &&
              menuItems.map(({ name, path, badgeContent }) => (
                <React.Fragment key={name}>
                  {path && (
                    <NavLink
                      className={({ isActive }) =>
                        `menu-item ${isActive ? 'active-menu-item ' : ''}`
                      }
                      to={path}
                    >
                      <Badge content={badgeContent?.content} type={badgeContent?.type}>
                        {name}
                      </Badge>
                    </NavLink>
                  )}

                  {!path && <div className="menu-item disabled-menu-item">{name}</div>}
                </React.Fragment>
              ))}
          </Box>

          {title && (
            <Typography variant="h1" className="appbar-title" style={{ marginLeft: '50px' }}>
              {title}
            </Typography>
          )}

          <Box sx={{ flexGrow: 1, display: { xs: 'flex' } }} />

          {menuItems && (
            <Box sx={{ flexGrow: 0, display: { xs: 'flex', lg: 'none' }, paddingRight: '3px' }}>
              <MobileMenu menuItems={menuItems} />
            </Box>
          )}

          {signInText && (
            <Link to="/portal" className="appbar-sign-in">
              {signInText}
            </Link>
          )}

          {userMenuItems && (
            <Box sx={{ flexGrow: 0 }}>
              <Avatar initials={initials} menuItems={userMenuItems} />
            </Box>
          )}
        </Toolbar>
      </Container>
    </MuiAppBar>
  );
}

AppBar.defaultProps = {
  environmentBannerText: undefined,
  initials: undefined,
  menuItems: undefined,
  rootPath: undefined,
  signInText: undefined,
  title: undefined,
  userMenuItems: undefined,
};

export default AppBar;
