import React from 'react';
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import { CircularProgress } from '@mui/material';

export interface ButtonProps {
  children?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: any;
  disabled?: boolean;
  endIcon?: MuiButtonProps['endIcon'];
  onClick?: MuiButtonProps['onClick'];
  size?: 'small' | 'medium' | 'large';
  startIcon?: MuiButtonProps['startIcon'];
  type?: 'info' | 'outlined' | 'primary' | 'secondary' | 'success';
  width?: string;
  loading?: boolean;
  buttonType?: MuiButtonProps['type'];
  ariaHasPopup?: MuiButtonProps['aria-haspopup'];
  ariaControls?: MuiButtonProps['aria-controls'];
  ariaExpanded?: MuiButtonProps['aria-expanded'];
  id?: MuiButtonProps['id'];
  style?: MuiButtonProps['style'];
  rounded?: boolean;
  to?: string;
}

function Button({
  children,
  component,
  disabled,
  endIcon,
  onClick,
  size,
  startIcon,
  type,
  width,
  loading,
  buttonType,
  ariaHasPopup,
  ariaControls,
  ariaExpanded,
  id,
  style,
  rounded,
  to,
}: ButtonProps) {
  let buttonVariant: MuiButtonProps['variant'] = 'contained';
  let color: MuiButtonProps['color'];

  const sx: MuiButtonProps['sx'] = {
    minWidth: width,
    borderRadius: rounded ? '300px' : undefined,
  };

  switch (type) {
    case 'info':
      color = 'info';
      break;

    case 'outlined':
      buttonVariant = 'outlined';
      break;

    case 'secondary':
      color = 'secondary';
      break;

    case 'success':
      color = 'success';
      break;

    case 'primary':
    default:
      color = 'primary';
      break;
  }

  return (
    <MuiButton
      style={style}
      id={id}
      aria-haspopup={ariaHasPopup}
      aria-controls={ariaControls}
      aria-expanded={ariaExpanded}
      type={buttonType}
      color={color}
      component={component}
      disabled={disabled || loading}
      disableElevation
      endIcon={endIcon}
      onClick={onClick}
      size={size}
      startIcon={startIcon}
      sx={sx}
      to={to}
      variant={buttonVariant}
    >
      {loading ? <CircularProgress /> : children}
    </MuiButton>
  );
}

Button.defaultProps = {
  children: '',
  component: undefined,
  disabled: false,
  endIcon: null,
  onClick: null,
  size: 'medium',
  startIcon: null,
  type: 'primary',
  width: '',
  loading: false,
  buttonType: undefined,
  ariaHasPopup: undefined,
  ariaControls: undefined,
  ariaExpanded: undefined,
  id: undefined,
  style: undefined,
  rounded: false,
  to: undefined,
};

export default Button;
